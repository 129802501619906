import { makeStyles, createMuiTheme } from "@material-ui/core/styles";

// Main theme and responsive fonts
export function makeTheme(mode) {
  return createMuiTheme({
    palette: {
      type: mode,
      primary: {
        light: "#1E1E1E",
        main: "#1E1E1E",
        dark: "#000000",
        contrastText: "#fff",
      },
      secondary: {
        light: "#ffd35f",
        main: "#ffc838",
        dark: "#b28c27",
        contrastText: "#000",
      },
      background: {
        paper: mode === "dark" ? "#1E1E1E" : "#ffffff",
      },
      layersControl: {
        paper: "#ffffff",
      },
    },
  });
}

// Sidebar
const drawerWidth = 240;
export const sidebarStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,    
  },
  siteToolBar: {
    zIndex: theme.zIndex.drawer,
    position: "sticky",
    top: 0,
    backgroundColor: "#ffffff",
    // padding: "50px",
    // fontSize: "20px",
    borderBottom: "thin solid #000000"
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: "auto",
  },
  content: {
    flexGrow: 1,
    padding: 0,
  },
}));

export const gridStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  gridRow1: {
    // flexGrow: 1,
    marginBottom: theme.spacing(0),
  },
  menuListContainer: {
    // background: "#4d4dff",
    //float: 'left',
    // marginLeft: "5px",
    // marginRight: "5px",
    // marginBottom: "10px",
    marginTop: "0px",
    paddingTop: "0px",
    // left: "75%",
  },
  formLabel: {
    marginLeft: "5px",
    paddingLeft: "5px",
  },
}));

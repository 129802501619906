/**
 * @fileOverview the main UI component
 */

import React, { useState, useRef, useEffect, useMemo } from "react";

import logo from "./logo.svg";
import "./App.css";
import { ThemeProvider, responsiveFontSizes } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CssBaseline from "@material-ui/core/CssBaseline";
// import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeTheme, sidebarStyles, gridStyles } from "./ui/styles";
import "./App.scss";

import { GlobalProvider } from "./context/GlobalContext.js";
// import StatewideLayout from "./layouts/StatewideLayout";
// import SitesLayout from "./layouts/SitesLayout";
import Layout from "./layouts/Layout";

import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuIcon from "@material-ui/icons/Menu";
// import AccountCircle from "@material-ui/icons/AccountCircle";

import { geographies, parkingSites } from "./common/constants";

import Grid from "@material-ui/core/Grid";

import AppBarUI from "./ui/AppBarUI";

import useScrollTrigger from '@mui/material/useScrollTrigger';
import Fade from '@mui/material/Fade';
import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PropTypes from 'prop-types';

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

function ScrollTop(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 0,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor',
    );

    if (anchor) {
      anchor.scrollIntoView({
        block: 'start',
      });
    }
  };

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 2, left: 2 }}
      >
        {children}
      </Box>
    </Fade>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

function App(props) {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const mode = prefersDarkMode ? "dark" : "light";
  let theme = makeTheme(mode);
  theme = responsiveFontSizes(theme);
  const classes = sidebarStyles();

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}id="back-to-top-anchor">
        <CssBaseline />
        <GlobalProvider>
          <AppBarUI />
          <main className={classes.content} >
            <div className={classes.toolbar} />
            <Layout/>
          </main>
        </GlobalProvider>
        <ScrollTop {...props}>
        <Tooltip title={"Scroll to Top"}>

              <Fab size="small" aria-label="scroll back to top" color="black" sx={{opacity: 0.75, backgroundColor: "black"}}>
                <ArrowUpwardIcon  size="large" sx={{color: "white"}}/>
              </Fab>
              </Tooltip>
              </ScrollTop>

      </div>
    </ThemeProvider>
  );
}

export default App;

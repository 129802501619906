/**
 * @fileOverview the main UI component
 */

import React, { useState, useRef, useEffect, useMemo, useContext } from "react";

import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import SettingsIcon from '@mui/icons-material/Settings';
import Menu from "@mui/material/Menu";
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import Grid from "@material-ui/core/Grid";
import Box from "@mui/material/Box";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import StateYrMnthSelect from "../../map/StateYrMnthSelect";
import { Typography } from "@material-ui/core";
import Slider from '@mui/material/Slider';

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import {
  
  vehicleWeightClassOptions,
} from "../../common/constants";
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';

import { makeTheme, sidebarStyles, gridStyles } from "../../ui/styles";

import { useGlobalState, useGlobalUpdater } from "../../context/GlobalContext";

import { useTheme } from '@mui/material/styles';
import { sitesYearsMonths, sitesYears, sitesMonths } from "../../common/constants";

export default function StatewideToolBar(props) {
    const { hexRadius, setHexRadius, hexElevationScale, setHexElevationScale, hexOpacity, setHexOpacity, colorRange, setColorRange, 
        selectedVehicleWeightClass, setSelectedVehicleWeightClass, selectedDayType, setSelectedDayType } = props;
    const classes = sidebarStyles();
    const classesGrid = gridStyles();
    const theme = useTheme();
   
    const query = useGlobalState();
    const dispatch = useGlobalUpdater();

    const colorRanges = [
        {
          name: "Standard",
          values: [
            [1, 152, 189],
            [73, 227, 206],
            [216, 254, 181],
            [254, 237, 177],
            [254, 173, 84],
            [209, 55, 78]
          ]
        },
        {
          name: "Green",
          values: [
            [255,255,204],
            [217,240,163],
            [173,221,142],
            [120,198,121],
            [49,163,84],
            [0,104,55],
          ]
        },
        {
          name: "Purple",
          values: [
            [241,238,246],
            [212,185,218],
            [201,148,199],
            [223,101,176],
            [221,28,119],
            [152,0,67],
          ]
        },
        {
          name: "Red",
          values: [
            [255,255,178],
            [254,217,118],
            [254,178,76],
            [253,141,60],
            [240,59,32],
            [189,0,38],
          ]
        },
        {
          name: "Qualitative",
          values: [
            [166,206,227],
            [31,120,180],
            [178,223,138],
            [51,160,44],
            [251,154,153],
            [227,26,28],
          ]
        },
        {
          name: "Diverging",
          values: [
            [69,117,180],
            [145,191,219],
            [224,243,248],
            [254,224,144],
            [252,141,89],
            [215,48,39],
          ]
        },
    ];
  
    const dayTypeOptions = [
        { label: "All Daytypes", value: 10 },
        { label: "Monday", value: 1 },
        { label: "Tuesday", value: 2 },
        { label: "Wednesday", value: 3 },
        { label: "Thursday", value: 4 },
        { label: "Friday", value: 5 },
        { label: "Saturday", value: 6 },
        { label: "Sunday", value: 7 },
      ];
  
    const handleHexRadiusSliderChange = (event, newValue) => {
        console.log("slider-event-check", Event)
        setHexRadius(newValue);
      };

    const [anchorMenuMapSettings, setAnchorMenuMapSettings] = React.useState(null);
    const handleMenuMapSettings = (event) => {
        setAnchorMenuMapSettings(event.currentTarget);
    };
    const handleCloseMenuMapSettings = () => {
        setAnchorMenuMapSettings(null);
        // setStatusGuide("not statewide");
    };
      
    const handleHexElevationScaleSliderChange = (event, newValue) => {
        // console.log("slider-event-check", Event)
        setHexElevationScale(newValue);
    };  

    const handleHexOpacitySliderChange = (event, newValue) => {
        // console.log("slider-event-check", Event)
        setHexOpacity(newValue);
    };

    const colorRangeChange = (event) => {
        console.log("event-check", event)
        setColorRange(colorRanges.filter((obj) => obj.name === event.target.value)[0]);
    };
  
    const handleChangeVehicleWeightClass = (event) => {
        setSelectedVehicleWeightClass(parseInt(event.target.value));
        // if (event.target.value === "leaflet") setDeckGlMapTypeOptionsEnabled(true);
        // else setDeckGlMapTypeOptionsEnabled(false);
        dispatch({
          type: "setSelectedVehicleWeightClass",
          selection: event.target.value,
        });
    };
  
    const handleChangeDayType = (event) => {
        setSelectedDayType(event.target.value);
        // if (event.target.value === "leaflet") setDeckGlMapTypeOptionsEnabled(true);
        // else setDeckGlMapTypeOptionsEnabled(false);
        dispatch({
          type: "setSelectedDayType",
          selection: event.target.value,
        });
    };

    const [selectedDurations, setSelectedDurations] = React.useState([
      "Less than 1 hour",
      "1 to 3 hours",
      "3 to 7 hours",
      "7 to 11 hours",
      "Greater than 11 hours",
    ]);

    const handleChange = (event) => {
      const {
        target: { value },
      } = event;
      setSelectedDurations(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
      );
      // console.log("duration-check", value)
      let durationKeys = [];
      const filteredParkingDurations = parkingDurations.filter(item => value.includes(item.name));
      filteredParkingDurations.map((obj) => {
          durationKeys.push(obj.key)
      })
      // console.log("durationKeys-check", durationKeys)
      dispatch({
          type: "setRegionSelected",
          selection: {
            ...query.regionSelected,
            parkingDurationCategories: durationKeys,
          },
        });
    };
  
  const parkingDurations = [
      {name:"Less than 1 hour", key:1},
      {name:"1 to 3 hours", key:2},
      {name:"3 to 7 hours", key:3},
      {name:"7 to 11 hours", key:4},
      {name:"Greater than 11 hours", key:5},
    ];
    function getStyles(name, selectedDurations, theme) {
        return {
          fontWeight: selectedDurations.includes(name)
            ? theme.typography.fontWeightMedium
            : theme.typography.fontWeightRegular,
        };
      }
      
  const handleChangeYear = (event) => {
    let regionSelected = query.regionSelected;
    let yearMonthTemp = sitesYearsMonths.filter(
      (obj) =>
        obj.year === event.target.value && obj.month === regionSelected.month
    )[0].yearMonth;
    dispatch({
        type: "setRegionSelected",
        selection: {
          ...query.regionSelected,
          year: event.target.value,
          yearMonth: yearMonthTemp,
        },
      });
    };
  
    const handleChangeMonth = (event) => {
        let regionSelected = query.regionSelected;
        let yearMonthTemp = sitesYearsMonths.filter(
          (obj) =>
            obj.year === regionSelected.year && obj.month === event.target.value
        )[0].yearMonth;
        // setRegionSelected({
        //   selected: regionSelected.selected,
        //   year: regionSelected.year,
        //   month: event.target.value,
        //   yearMonth: yearMonthTemp,
    
        //   // selected: regionSelected.selected,
        //   // year: optionsSplit[0] * 1,
        //   // month: optionsSplit[1] * 1,
        //   //   yearMonthOptionsIndex: index,
        //   geographyOptionsIndex: regionSelected.geographyOptionsIndex,
        //   regionParkingDataType: regionSelected.regionParkingDataType,
        //   parkingDurationCategories: regionSelected.parkingDurationCategories,
        // });
    
        dispatch({
          type: "setRegionSelected",
          selection: {
            ...query.regionSelected,
            month: event.target.value,
            yearMonth: yearMonthTemp,
          },
        });
      };
    
    return (
        <div id="sticky-div" className={classes.siteToolBar}>
            <Toolbar variant="dense">
                <Grid container spacing={1} >
                <Grid item xs={2} style={{m: 0}}>
                    <Typography
                        variant="button"
                        // onClick={() => setVal(MIN)}
                        sx={{ cursor: 'pointer' }}
                        >
                        <Box fontWeight='fontWeightMedium' display='inline'>
                            Map Settings
                        </Box>
                        </Typography>
                </Grid>
                    <Grid item xs={10} sx={{m: 0}}>
                    <Typography
                        variant="button"
                        // onClick={() => setVal(MIN)}
                        sx={{ cursor: 'pointer' }}
                        >
                        <Box fontWeight='fontWeightMedium' display='inline' >
                            Data Settings      
                        </Box>
                        </Typography>
                    </Grid>
                <Grid item xs={1} >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between',mr:2 }}>
                        <Typography id="input-slider" variant="body2" sx={{ mb:0,pb:0}}>
                        Grid Size:
                        </Typography>
                    </Box>
                    <Slider
                    sx={{mt:0,pt:0}}
                    size="small"
                        value={hexRadius}
                        onChange={handleHexRadiusSliderChange}
                        aria-labelledby="input-hexradius-slider"
                        step={10}
                        // defaultValue={20}
                        max={2000}
                        min={20}
                        marks={[
                            {
                            value: 20,
                            label: '',
                            },
                            // {
                            //   value: 1000,
                            //   label: 'Medium',
                            // },
                            {
                            value: 2000,
                            label: '',
                            },
                        ]}
                        // valueLabelDisplay="auto"
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography
                        variant="body2"
                        // onClick={() => setVal(MIN)}
                        sx={{ cursor: 'pointer' }}
                        >
                        Small
                        </Typography>
                        <Typography
                        variant="body2"
                        // onClick={() => setVal(MAX)}
                        sx={{ cursor: 'pointer' }}
                        >
                        Large
                        </Typography>
                    </Box>
                    </Grid>
                    <Grid item xs={1} >
                    <Box sx={{ 
                        // width: "10vw",
                        position: "relative",
                        display: "flex",
                        alignItems: "right",
                        justifyContent: "right",
                    }}>
                    <Tooltip title={
                    <React.Fragment>
                        <Typography color="inherit">Other Grid Settings</Typography>
                        <em>{"Elevation Scale"}</em>
                        <br />
                        <em>{"Opacity"}</em>
                        <br />
                        <em>{"Color Scheme"}</em>
                    </React.Fragment>
                    }>
                        <IconButton
                            size="small"
                            aria-label="map grid settings menu"
                            aria-controls="map-grid-settings-menu"
                            aria-haspopup="true"
                            onClick={handleMenuMapSettings}
                            color="inherit"
                        >
                        <Stack direction="row" justifyContent="space-evenly">
                            <Stack direction="column" justifyContent="space-evenly">
                            <Box sx={{ mr: 0.5 }}>
                                <Typography variant="body2" noWrap>
                                Other Map
                                </Typography>
                            
                                <Typography variant="body2" noWrap>
                                Settings
                                </Typography>
                            </Box>
                            </Stack>
                            <SettingsIcon />
                        </Stack>
                        </IconButton>
                    </Tooltip>
                        <Menu
                            id="menu-grid-settings"
                            // aria-labelledby="menu-grid-settings"
                            anchorEl={anchorMenuMapSettings}
                            anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                            }}
                            keepMounted
                            transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                            }}
                            open={Boolean(anchorMenuMapSettings)}
                            // onClose={handleCloseParkingSitesMenu}
                            onClose={handleCloseMenuMapSettings}
                            
                        > 
                        <Box sx={{ m: 0.0, mt: 0, pt: 0, ml:2 }}>
                            <Stack direction="row" justifyContent="space-evenly">
                            <Typography textAlign="left" variant="h6" sx={{fontWeight: 'bold'}}>Grid (Hexagon) Settings</Typography>
                            <CloseIcon
                                sx={{
                                fontSize: 22,
                                ml: 1,
                                pb: 1,
                                mt: 0,
                                mb: 0,
                                pt: 0,
                                mr: 0,
                                pr: 0,
                                }}
                                onClick={handleCloseMenuMapSettings}
                            />
                            </Stack>
                        </Box>
                        <Divider sx={{ my: 0.25 }} />
                        <Stack direction="column" justifyContent="space-evenly" sx={{ml:2,mr:2}}>    
                            <Typography id="input-slider" variant="h6" sx={{ml:0, mb:0,pb:0}}>
                            Elevation Scale:
                            </Typography>
                            <Slider
                            sx={{mt:0,pt:0}}
                            size="small"
                            value={hexElevationScale}
                            onChange={handleHexElevationScaleSliderChange}
                            aria-labelledby="input-elevation-scale-slider"
                            step={0.1}
                            max={100}
                            min={0.1}
                            marks={[
                                {
                                value: 0.1,
                                label: '',
                                },
                                {
                                value: 5,
                                label: '',
                                },
                            ]}
                            // valueLabelDisplay="auto"
                            />
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography
                                variant="body2"
                                // onClick={() => setVal(MIN)}
                                sx={{ cursor: 'pointer' }}
                            >
                                Small
                            </Typography>
                            <Typography
                                variant="body2"
                                // onClick={() => setVal(MAX)}
                                sx={{ cursor: 'pointer' }}
                            >
                                Large
                            </Typography>
                            </Box>
                            <Typography id="input-slider" variant="h6" sx={{ml:0, mb:0,pb:0}}>
                            Opacity:
                            </Typography>
                            <Slider
                            sx={{mt:0,pt:0}}
                            size="small"
                            value={hexOpacity}
                            onChange={handleHexOpacitySliderChange}
                            aria-labelledby="input-Opacity-slider"
                            step={0.05}
                            max={1}
                            min={0}
                            marks={[
                                {
                                value: 0.1,
                                label: '',
                                },
                                {
                                value: 5,
                                label: '',
                                },
                            ]}
                            // valueLabelDisplay="auto"
                            />
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography
                                variant="body2"
                                // onClick={() => setVal(MIN)}
                                sx={{ cursor: 'pointer' }}
                            >
                                Light
                            </Typography>
                            <Typography
                                variant="body2"
                                // onClick={() => setVal(MAX)}
                                sx={{ cursor: 'pointer' }}
                            >
                                Dark
                            </Typography>
                            </Box>
                            <Typography id="input-slider" variant="h6" sx={{ml:0, mb:0,pb:0,fontWeight: 'bold'}}>
                            Color Scheme:
                            </Typography>
                            <FormControl>
                {/* <FormLabel id="map-color-scale-group-label">Gender</FormLabel> */}
                <RadioGroup
                    row
                    aria-labelledby="map-color-scale-radio-buttons-group-label"
                    name="map-color-scale-radio-buttons-group"
                    onChange={colorRangeChange}
                    value={colorRange.name}
                >
                    <Box sx={{width:"50%"}}>
                    <FormControlLabel value="Standard" control={<Radio />} label="Standard" />
                    </Box>
                    <Box sx={{width:"50%"}}>
                    <FormControlLabel value="Green" control={<Radio />} label="Green" />
                    </Box>
                </RadioGroup>
                <RadioGroup
                    row
                    aria-labelledby="map-color-scale-radio-buttons-group-label2"
                    name="map-color-scale-radio-buttons-group2"
                    onChange={colorRangeChange}
                    value={colorRange.name}
                >
                    <Box sx={{width:"50%"}}>
                    <FormControlLabel value="Red" control={<Radio />} label="Red" />
                    </Box>
                    <Box sx={{width:"50%"}}>
                    <FormControlLabel value="Purple" control={<Radio />} label="Purple" />
                    </Box>
                </RadioGroup>
                <RadioGroup
                    row
                    aria-labelledby="map-color-scale-radio-buttons-group-label3"
                    name="map-color-scale-radio-buttons-group3"
                    onChange={colorRangeChange}
                    value={colorRange.name}
                >
                    <Box sx={{width:"50%"}}>
                    <FormControlLabel value="Qualitative" control={<Radio />} label="Qualitative" />
                    </Box>
                    <Box sx={{width:"50%"}}>
                    <FormControlLabel value="Diverging" control={<Radio />} label="Diverging" />
                    </Box>
                </RadioGroup>
                </FormControl>
                        </Stack>
                            </Menu>
                    </Box>
                    </Grid>
                    <Grid item xs={1}>
                    <FormControl sx={{ m: 0, mt: 1}} size="small" fullWidth>
                        <InputLabel id="year-label">Year:</InputLabel>
                        <Select
                        labelId="year-select-label"
                        id="year-select"
                        value={query.regionSelected.year}
                        onChange={handleChangeYear}
                        input={<OutlinedInput label="Year:"  sx={{fontSize: '0.9rem'}}/>}
                        Year
                        // MenuProps={MenuProps}
                        >
                        {sitesYears.map((option, index) => (
                            <MenuItem
                            key={"state-year-" + option}
                            value={option}
                            // style={getStyles(obj.name, selectedDurations, theme)}
                            >
                            {option}
                            </MenuItem>
                        ))}
                        </Select>
                    </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                    <FormControl sx={{ m: 0, mt: 1}} size="small" fullWidth>
                        <InputLabel id="month-label">Month:</InputLabel>
                        <Select
                        labelId="month-select-label"
                        id="month-select"
                        value={query.regionSelected.month}
                        onChange={handleChangeMonth}
                        input={<OutlinedInput label="Month:"  sx={{fontSize: '0.9rem'}}/>}
                        // MenuProps={MenuProps}
                        >
                        {sitesMonths.map((option, index) => (
                            <MenuItem
                            key={"state-mnth-" + option.month}
                            value={option.month}
                            // style={getStyles(obj.name, selectedDurations, theme)}
                            >
                            {option.description}
                            </MenuItem>
                        ))}
                        </Select>
                    </FormControl>
                    </Grid>
                    <Grid item xs={3} >
                    <FormControl sx={{ m: 0, mt: 1 }} size="small" fullWidth>
        <InputLabel id="demo-multiple-name-label">Duration:</InputLabel>
        <Select
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          multiple
          value={selectedDurations}
          onChange={handleChange}
          input={<OutlinedInput label="Duration:"  sx={{fontSize: '0.9rem'}}/>}
        //   MenuProps={MenuProps}
        >
          {parkingDurations.map((obj) => (
            <MenuItem
              key={obj.key}
              value={obj.name}
              style={getStyles(obj.name, selectedDurations, theme)}
            >
              {obj.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

                    </Grid>
                    <Grid item xs={2}>
                    <FormControl sx={{ m: 0, mt: 1}} size="small" fullWidth>
                        <InputLabel id="vehicle-weight-class-label">Vehicle Weight Class:</InputLabel>
                        <Select
                        labelId="vehicle-weight-class-select-label"
                        id="vehicle-weight-class-select"
                        value={selectedVehicleWeightClass}
                        onChange={handleChangeVehicleWeightClass}
                        input={<OutlinedInput label="Vehicle Weight Class:"  sx={{fontSize: '0.9rem'}}/>}
                        // MenuProps={MenuProps}
                        >
                        {vehicleWeightClassOptions.map((option, index) => (
                            <MenuItem
                            key={"vehicleweightclass-" + option.value}
                            value={option.value}
                            // style={getStyles(obj.name, selectedDurations, theme)}
                            >
                            {option.label}
                            </MenuItem>
                        ))}
                        </Select>
                    </FormControl>
                    </Grid>
            
                    <Grid item xs={2} >
                    <FormControl sx={{ m: 0, mt: 1 }} size="small" fullWidth>
                        <InputLabel id="day-of-week-label">Day of Week:</InputLabel>
                        <Select
                        labelId="day-of-week-select-label"
                        id="day-of-week-select"
                        value={selectedDayType}
                        onChange={handleChangeDayType}
                        input={<OutlinedInput label="Day of Week:"  sx={{fontSize: '0.9rem'}}/>}
                        // MenuProps={MenuProps}
                        >
                        {dayTypeOptions.map((option, index) => (
                            <MenuItem
                            key={"dayofweek-" + option.value}
                            value={option.label}
                            // style={getStyles(obj.name, selectedDurations, theme)}
                            >
                            {option.label}
                            </MenuItem>
                        ))}
                        </Select>
                    </FormControl>
                    </Grid>
                </Grid>
            </Toolbar >
            
        </div>
    )   
}

import React, { useState, useRef, useEffect, useMemo, useContext } from "react";
import AppBar from "@material-ui/core/AppBar";
import { makeTheme, sidebarStyles, gridStyles } from "./styles";
import { ThemeProvider, responsiveFontSizes } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuIcon from "@material-ui/icons/Menu";
import MenuItem from "@mui/material/MenuItem";

import { geographies, parkingSites } from "../common/constants";

// import { QueryContext } from "../context/QueryContext";
import { useGlobalState, useGlobalUpdater } from "../context/GlobalContext";

import HelpIcon from "@mui/icons-material/Help";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";

export default function AppBarUI(props) {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const mode = prefersDarkMode ? "dark" : "light";
  let theme = makeTheme(mode);
  theme = responsiveFontSizes(theme);
  const classes = sidebarStyles();
  // const [query, dispatch] = useContext(QueryContext);
  const query = useGlobalState();
  const dispatch = useGlobalUpdater();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElRegion, setAnchorElRegion] = React.useState(null);
  const [anchorElHelp, setAnchorElHelp] = React.useState(null);

  // const handleChange = (event) => {
  //   setAuth(event.target.checked);
  // };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuRegion = (event) => {
    setAnchorElRegion(event.currentTarget);
  };
  const handleMenuHelp = (event) => {
    setAnchorElHelp(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleCloseMenuRegion = () => {
    setAnchorElRegion(null);
  };
  const handleCloseMenuHelp = () => {
    setAnchorElHelp(null);
    // setStatusGuide("not statewide");
  };

  const handleClickMenuParkingSite = (item, event) => {
    // console.log("item-check", item);
    setAnchorEl(null);

    dispatch({
      type: "setRegionSelected",
      selection: {
        selected: null,
        year: 2021,
        month: 1,
        yearMonthOptionsIndex: 36,
        // geographyOptionsIndex: 1,
        geographyOptionsIndex: null,
        regionParkingDataType: 0,
        parkingDurationCategories:
          query.regionSelected.parkingDurationCategories,
      },
    });

    let tempSelectionSite = query.selectedSite;
    // console.log("query test good");
    dispatch({
      type: "setSelectedSite",
      selection: { ...tempSelectionSite, selected: item.name },
    });
  };
  const handleClickMenuRegion = (item, event) => {
    // console.log("item-check", item);
    setAnchorElRegion(null);

    // dispatch({
    //   type: "setRegionSelected",
    //   selection: {
    //     selected: item.name,
    //     year: 2021,
    //     month: 1,
    //     yearMonthOptionsIndex: 36,
    //     // geographyOptionsIndex: 1,
    //     geographyOptionsIndex: geographies.findIndex(
    //       (obj) => obj.label === item.name
    //     ),
    //     regionParkingDataType: 0,
    //     parkingDurationCategories:
    //       query.regionSelected.parkingDurationCategories,
    //   },
    // });
    dispatch({
      type: "setRegionSelected",
      selection: {
        ...query.regionSelected,
        selected: item.name,
        geographyOptionsIndex: geographies.findIndex(
          (obj) => obj.label === item.name
        ),
      },
    });

    let tempSelectionSite = query.selectedSite;
    dispatch({
      type: "setSelectedSite",
      selection: { ...tempSelectionSite, selected: null },
    });
  };

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <Grid container justifyContent="center" spacing={0}>
          <Grid item xs={1}>
            <Box
              height="100%"
              display="flex"
              justifyContent="center"
              flexDirection="column"
              alignContents={"left"}
            >
              <img
                className="logo"
                src="logo-light.svg"
                alt="Maryland DOT Logo"
                title="Maryland DOT Logo"
                style={{height:"4vh"}}
              />
            </Box>
          </Grid>
          <Grid item xs={1}>
            <Box
              height="100%"
              display="flex"
              justifyContent="center"
              flexDirection="column"
            >
              {/* <Typography variant="caption" noWrap>
                    Developed By:
                  </Typography> */}
              <img
                // className="logo"
                src={"TTI-Color-reverse.png"}
                alt="TTI Logo"
                title="TTI Logo"
                style={{height:"4vh"}}
                />
            </Box>
          </Grid>

          <Grid
            item
            xs={8}
            align="center"
            // justify="center"
            // sx={{ padding: 0, margin: 0 }}
          >
            <Box
              height="100%"
              display="flex"
              justifyContent="center"
              flexDirection="column"
              sx={{ mr: 21 }}
            >
              <Typography variant="h4" noWrap style={{fontSize:"2em"}}>
                Maryland Truck Parking Visualization Tool
              </Typography>
            </Box>
          </Grid>

          {/* <Box sx={{ m: 0.5 }}>
                <IconButton
                  size="medium"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleStatewideClick}
                  color="inherit"
                >
                  <Typography variant="h6" noWrap>
                    Region/Statewide
                  </Typography>
                </IconButton>
              </Box> */}
          <Grid item xs={2} align="right">
            <Box
              height="100%"
              display="flex"
              justifyContent="flex-end"
              flexDirection="row"
            >
              <IconButton
                size="small"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenuRegion}
                color="inherit"
              >
                <Box sx={{ m: 0.5 }}>
                  <Typography noWrap >
                    Region/Statewide
                  </Typography>
                </Box>
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElRegion}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElRegion)}
                // onClose={handleCloseParkingSitesMenu}
                onClose={handleCloseMenuRegion}
              >
                {geographies.map((item) => (
                  <MenuItem
                    key={"region-" + item.id}
                    onClick={(event) => handleClickMenuRegion(item, event)}
                    // onClick={(event) => handleClickMenuRegion(item, event)}
                  >
                    <Typography textAlign="center">{item.name}</Typography>
                  </MenuItem>
                ))}
              </Menu>
              {/* </Box>
              </Grid>
              <Grid item xs={2} align="right">
                <Box
                  height="100%"
                  display="flex"
                  justifyContent="flex-end"
                  flexDirection="row"
                > */}
              {/* <div> */}
              <IconButton
                size="small"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <Box sx={{ m: 0.5 }}>
                  <Typography noWrap>
                    Parking Sites
                  </Typography>
                </Box>
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
              >
                {/* <MenuItem onClick={handleClose}>Profile</MenuItem>
                  <MenuItem onClick={handleClose}>My account</MenuItem> */}
                {parkingSites.map((item) => (
                  <MenuItem
                    key={"site-" + item.id}
                    // onClick={handleClickMenuParkingSite}
                    onClick={(event) => handleClickMenuParkingSite(item, event)}
                    // value={site}
                  >
                    <Typography textAlign="center">{item.name}</Typography>
                  </MenuItem>
                ))}
              </Menu>
              <IconButton
                // size="small"
                aria-label="help menu"
                aria-controls="help-menu-appbar"
                aria-haspopup="true"
                onClick={handleMenuHelp}
                color="inherit"
                
              >
                <HelpIcon style={{alignItems:"right", fontSize:"1em"}}/>
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElHelp}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElHelp)}
                // onClose={handleCloseParkingSitesMenu}
                onClose={handleCloseMenuHelp}
              >
                <Box sx={{ m: 0.25, mt: 0, pt: 0 }}>
                  <Stack direction="row" justifyContent="space-evenly">
                    <Typography textAlign="center">Help</Typography>

                    <CloseIcon
                      sx={{
                        fontSize: 22,
                        ml: 4,
                        pb: 1,
                        mt: 0,
                        mb: 0,
                        pt: 0,
                        mr: 0,
                        pr: 0,
                      }}
                      onClick={handleCloseMenuHelp}
                    />
                  </Stack>
                </Box>
                <Divider sx={{ my: 0.25 }} />
                <MenuItem
                  key={"help-guidebook"}
                  // onClick={(event) => handleClickMenuRegion(item, event)}
                  href={
                    process.env.PUBLIC_URL +
                    "MD Truck Parking Visualization Tool Guide FINAL 5-16-2023.pdf"
                  }
                  // href="https://tti-my.sharepoint.com/:w:/g/personal/n-katsikides_tti_tamu_edu/EZXh2J4f0rlBrTRjh7TAfPsBtyZlmt-XyRvZl4hEJUXwXg?e=Kh3dsB"
                  target="_blank"
                  component="a"
                >
                  <Typography textAlign="center">Guidebook</Typography>
                </MenuItem>
                <MenuItem
                  key={"help-video"}
                  // onClick={(event) => handleClickMenuRegion(item, event)}
                  href="https://tti-my.sharepoint.com/:v:/g/personal/n-katsikides_tti_tamu_edu/EQ6xXpVG9PNIllQnfDP6J-ABYS2sJtVX4Q3KPgOGjLf0GQ?e=ryO4Iz"
                  target="_blank"
                  component="a"
                >
                  <Typography textAlign="center">Video Help</Typography>
                </MenuItem>
              </Menu>
            </Box>

            {/* </div> */}
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}
